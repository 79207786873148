import React, { useState } from "react";
import { Badge, Tooltip } from "reactstrap";
import { handleCopyCode } from "../../Utils";

const CommonTooltip = ({ placement, id, content, children, color }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      <Badge color={color} id={id}>
        {children}
      </Badge>
      <Tooltip placement={placement} autohide={false} isOpen={tooltipOpen} target={id} toggle={toggle} onClick={() => handleCopyCode(content)}>
        {content}
      </Tooltip>
    </span>
  );
};

export default CommonTooltip;
