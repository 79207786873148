import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyEmailTokenApi } from "../../../Api/ApiList";
import UpdatePasswordContainer from "../../../Components/Auth/UpdatePassword/UpdatePasswordContainer";
import { Col, Row } from "reactstrap";

const UpdatePassword = () => {
  const [enterAuth, setEnterAuth] = useState(false);
  const toastShown = useRef(false);
  const navigate = useNavigate();
  const location = useLocation();
  const pathToken = location.pathname.split("/");
  const token = pathToken[pathToken.length - 1];
  const email = JSON.parse(localStorage.getItem("e-uat"));
  
  useEffect(() => {
    if (token && !toastShown.current && email) {
      const verifyTokenHandler = async () => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/${verifyEmailTokenApi}/${token}`,{email});
          if (response.status === 200) {
            if (!toastShown.current) {
              toast.success(response.data.message);
              toastShown.current = true;
            }
            setEnterAuth(true);
          }
        } catch (error) {
          console.log("🚀 ~ verifyTokenHandler ~ error:", error)
          if (!toastShown.current) {
            toast.error("Page Not Found !!!");
            toastShown.current = true;
          }
          setEnterAuth(false);
          navigate(`${process.env.PUBLIC_URL}/login`);
        }
      };
      verifyTokenHandler();
    }
  }, [token, navigate, email]);

  return (
    <Fragment>
      {enterAuth && (
        <div className="login-section">
          <Row className="login-content align-items-center g-0">
            <Col xs="5" className="mx-auto">
              <div className="login-main">
                <UpdatePasswordContainer token={token} email={email} />
              </div>
            </Col>
          </Row>
        </div>
      )} 
    </Fragment>
  );
};

export default UpdatePassword;
