import React from "react";
import { Container, Row } from "reactstrap";
import TreasuryBalance from "./LineChart/TreasuryBalance";
import BankBalance from "./LineChart/BankBalance";
import SmallCard from "./SmallCard/SmallCard";
import TopTreasury from "./ColumnChart/TopTreasury";
import TopBankTransfer from "./ColumnChart/TopBankTransfer";
import RecentTreasury from "./BottomCard/RecentTreasury";
import RecentBank from "./BottomCard/RecentBank";

const DashboardContainer = () => {
  return (
    <Container fluid className="pt-4 dashboard-section">
      <div>
        <h2>Company Transfer Tracking Dashboard</h2>
      </div>
      <Row className="g-4">
          <TreasuryBalance/>
          <BankBalance/>
          <SmallCard/>
          <TopTreasury/>
          <TopBankTransfer/>
          <RecentTreasury/>
          <RecentBank/>
      </Row>
    </Container>
  );
};
export default DashboardContainer;
