import React, { Fragment, useEffect, useState } from "react";
import { Edit, Plus } from "react-feather";
import { Button, Col, Container, Row, Table } from "reactstrap";
import { useGetApi } from "../../Api/useGetApi";
import { useAuth } from "../../Context/AuthContext";
import { useTransaction } from "../../Context/TransactionContext";
import useDebounce from "../../Hooks/useDebounce";
import CommonPagination from "../Common/CommonPagination";
import EditModal from "./EditModal";
import ImportModal from "./ImportModal";
import InnerLoader from "../../Layout/InnerLoader";
import CommonTooltip from "../Common/CommonTooltip";
import { formatDateTime } from "../../Utils";

const TransactionContainer = () => {
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const { logout } = useAuth();
  const { editModal, importModal, toggleImport, toggleEdit, setEditTransactionData, searchTerm, setSearchTerm } = useTransaction();
  const search = useDebounce(searchTerm, 500);

  const params = { page, limit, search };

  const { isLoading: isLoadingGetData, data: transactionData, refetch: refetchGetData } = useGetApi("/api/getTransactions", params);

  useEffect(() => {
    if (transactionData) {setTotalPages(transactionData.totalPages);}
  }, [transactionData]);

  const handleLimitChange = (e) => {
    setLimit(Number(e.target.value));
    setPage(1);
  };

  useEffect(() => {
    if (searchTerm.trim() === "") {setPage(1);}
  }, [searchTerm]);

  useEffect(() => {setPage(1);}, [searchTerm]);

  useEffect(() => {
    if (search.trim().length > 0) {isLoadingGetData && refetchGetData();}
  }, [search, page, limit, isLoadingGetData, refetchGetData]);

  const handleEdit = (id) => {
    const singleEditData = transactionData?.data?.find((item) => item?.id === id);
    if (singleEditData) {
      toggleEdit();
      setEditTransactionData(singleEditData);
    }
  };

  return (
    <Fragment>
      <Container fluid className="mt-5 pe-5 text-end">
        <Button onClick={logout}>Log out</Button>
      </Container>
      <section className="transaction-table">
        <div className="text-center mb-4">
          <h2>Transactions</h2>
        </div>
        <Row className="mb-3">
          <Col>
            <div className="d-flex align-items-center gap-2">
              <span>shows :</span>
              <select value={limit} onChange={handleLimitChange} type="select" className="form-select w-auto">
                {[25, 50, 75, 100].map((item) => (<option key={item} value={item}>{item}</option>))}
              </select>
              <span>Entries</span>
            </div>
          </Col>
          <Col className="d-flex align-items-center justify-content-end gap-2">
            <p className="mb-0">Search :</p>
            <div className="d-flex align-items-center w-50">
              <input type="text" className="form-control search-transaction" placeholder="Search ..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
            </div>
            <Button color="light border border-1" onClick={toggleImport}><Plus size={15} /> Import</Button>
          </Col>
        </Row>
        <Table bordered responsive>
          <thead>
            <tr>
              <th>No.</th>
              <th>Block Number</th>
              <th>Event Id</th>
              <th>From Account</th>
              <th>To Account</th>
              <th>Amount</th>
              <th>Timestamp</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {isLoadingGetData ? (
              <tr><td colSpan="10" className="text-center"><InnerLoader /></td></tr>
            ) : (
              <>
                {transactionData?.data?.length > 0 ? (
                  transactionData.data.map((item) => (
                    <tr key={item.id}>
                      <th scope="row">{item.id}</th>
                      <th>{item.block_number}</th>
                      <td>{item.event_id}</td>
                      <td>{item.from_label ? (<CommonTooltip placement="right" id={`TooltipExample-from-${item.id}`} content={item.from_account} color="danger">{item.from_label}</CommonTooltip>) : (item.from_account)}</td>
                      <td>{item.to_label ? (<CommonTooltip placement="right" id={`TooltipExample-to-${item.id}`} content={item.to_account} color="success">{item.to_label}</CommonTooltip>) : (item.to_account)}</td>
                      <td>{item.amount}</td>
                      <td>{formatDateTime(item.timestamp)}</td>
                      <td><Button className="bg-light d-flex align-items-center justify-content-center" onClick={() => handleEdit(item.id)}><Edit size={20} color="black" /></Button></td>
                    </tr>
                  ))
                ) : (
                  <tr className=""><td colSpan="10" className="text-center no-found">No data found</td></tr>
                )}
              </>
            )}
          </tbody>
        </Table>
        {!isLoadingGetData && transactionData?.data?.length > 0 && <CommonPagination totalPages={totalPages} currentPage={page} setCurrentPage={setPage} />}
      </section>
      {editModal && <EditModal refetchTransaction={refetchGetData} />}
      {importModal && <ImportModal refetchGetData={refetchGetData} />}
    </Fragment>
  );
};

export default TransactionContainer;