import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

const SmallCard = () => {
  return (
    <Col lg={12}>
      <Row>
        <Col lg={4}>
          <Card>
            <CardHeader>Total Transfers</CardHeader>
            <CardBody>1,234</CardBody>
          </Card>
        </Col>
        <Col lg={4}>
          <Card>
            <CardHeader>Treasury Balance</CardHeader>
            <CardBody>1,234</CardBody>
          </Card>
        </Col>
        <Col lg={4}>
          <Card>
            <CardHeader>Bank Balance</CardHeader>
            <CardBody>1,234</CardBody>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};
export default SmallCard;
