import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

const checkTokenAndNavigate = () => {
  const token = localStorage.getItem('finance_token');
  if (!token) {
    return false; // Token is missing, return false or handle as needed
  }
  return true; // Token is present
};

// Middleware to set Bearer token from local storage and handle navigation
axiosInstance.interceptors.request.use(
  (config) => {
    const tokenExists = checkTokenAndNavigate();
    if (tokenExists) {
      const token = localStorage.getItem('finance_token');
      const authToken = JSON.parse((token))
      config.headers['x-access-token'] = authToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;