import { Toaster } from "react-hot-toast";

// CustomToaster
export const CustomToaster = () => (
  <Toaster
    toastOptions={{
      duration: 3000,
      position: "top-right",
      style: {
        fontWeight: 700,
        minWidth: 200,
      },
      success: {
        style: {
          background: "rgb(229, 245, 227)",
          color: "rgba(var(--success), 1)",
        },
      },
      error: {
        style: {
          background: "rgb(255, 238, 237)",
          color: "rgba(var(--error), 1)",
        },
      },
    }}
  />
);

// Dynamic Image
const images = require.context(`/public/assets/images`, true);

export const dynamicImage = (image) => {
  return images(`./${image}`);
};

// Copy Text
export const handleCopyCode = (code) => navigator.clipboard.writeText(code);

// Date Formate
export const formatDateTime = (timestamp) => {
  const date = new Date(timestamp);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false, // use 24-hour format
  };

  // Format date and time
  const formattedDate = date.toLocaleDateString("en-GB", options).replace(/\//g, "-");

  return `${formattedDate}`;
};
