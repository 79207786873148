import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../Context/AuthContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Label } from "reactstrap";
import { Eye, Key } from "react-feather";

const UpdatePasswordContainer = ({ email, token }) => {
  const [show, setShow] = useState(false);
  const [updateSpin, SetUpdateSpin] = useState(false);
  const toggleShow = () => setShow(!show);
  const { resetPassSchema, handleResetPassword } = useAuth();
  const { register,handleSubmit,formState: { errors }} = useForm({ resolver: yupResolver(resetPassSchema) });
  const navigate = useNavigate();

  const onSubmit = (data) => handleResetPassword({ email, token, data, navigate, SetUpdateSpin });

  return (
    <Form className="auth-card" onSubmit={handleSubmit(onSubmit)} noValidate>
      <h2>Update Password</h2>
      <div className="auth-content">
        <FormGroup className="form-group">
          <Label>Password</Label>
          <div className="position-relative">
            <Key />
            <input className="form-control" {...register("password")} autoComplete="off" type={show ? "text" : "password"} placeholder="Enter Password ..." />
          </div>
          <div className={`toggle-password ${show ? "show" : ""}`} onClick={toggleShow}>
            <Eye />
          </div>
          {errors.password && <span className="text-danger fs-6">{errors.password.message}</span>}
        </FormGroup>
        <FormGroup className="form-group">
          <Label>Confirm Password</Label>
          <div className="position-relative">
            <Key />
            <input className="form-control" {...register("confirmPassword")} autoComplete="off" type={show ? "text" : "password"} placeholder="Enter Confirm Password ..." />
          </div>
          <div className={`toggle-password ${show ? "show" : ""}`} onClick={toggleShow}>
            <Eye />
          </div>
          {errors.confirmPassword && <span className="text-danger fs-6">{errors.confirmPassword.message}</span>}
        </FormGroup>

        <FormGroup className="mb-0 mt-5 loader-btn">
          <Button disabled={updateSpin ? true : false} type="submit" className="btn-solid">
            Confirm Password {updateSpin && <div className="loader"></div>}
          </Button>
        </FormGroup>
      </div>
    </Form>
  );
};

export default UpdatePasswordContainer;
