import { useQuery } from "@tanstack/react-query";
import axiosInstance from "./middleware";

export const useGetApi = (url, params) => {
  return useQuery({
    queryKey: [url, params],
    queryFn: async () => {
      const response = await axiosInstance.get(url, { params });
      return response.data;
    },
  });
};
