import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar/Sidebar";

const Layout = () => {
  return (
    <div className={`page-wrapper`}>
      <div className={`page-body-wrapper`}>
        {/* <Sidebar /> */}
        <div className="page-body">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
export default Layout;
