import { Col } from "reactstrap";

const FileUploadBrowser = ({ values, setFieldValue, ...props }) => {

  const onSelect = (event) => {
    const files = event.currentTarget.files;
    if (!files || files.length === 0) return;

    if (files.length > 1) {
      console.log("Please upload only one file at a time.");
      return;
    }
    
    setFieldValue(props.name, files[0]);
  };

  const handleClick = (event) => {
    event.currentTarget.value = "";
  };

  return (
    <>
      {!values[props.name] && <a href="#javascript" className="font-blue browse-file">
        Browse files
        <input {...props} onChange={onSelect} onClick={handleClick} />
      </a>}
      <div className={`overflow-section custom-scroll ${!values[props.name] ? "d-none" : ""}`}>
        <div className="image-selection-list g-sm-4 g-3">
          <Col container >
            {values[props.name] && (
              <div className="import-list">
                <h6 className="pt-1">{values[props.name].name}</h6>
                <h6>{values[props.name].type}</h6>
              </div>
            )}
          </Col>
        </div>
      </div>
    </>
  );
};

export default FileUploadBrowser;