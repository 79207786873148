import React from "react";
import { Card, CardBody, CardHeader, Col, Table } from "reactstrap";

const RecentTreasury = () => {
  return (
    <Col lg="6">
      <Card>
        <CardHeader>Recent Treasury Transfers</CardHeader>
        <CardBody>
          <Table>
            <thead>
              <th>From</th>
              <th>To</th>
              <th>Amount</th>
            </thead>
            <tbody>
              <tr>
                <td>Treasury</td>
                <td>1234</td>
                <td>100,000</td>
              </tr>
              <tr>
                <td>Treasury</td>
                <td>5678</td>
                <td>50,000</td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Col>
  );
};
export default RecentTreasury;
