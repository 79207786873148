import React from "react";
import { Card, CardBody, CardHeader, Col, Table } from "reactstrap";

const RecentBank = () => {
  return (
    <Col lg="6">
      <Card>
        <CardHeader>Recent Bank Transfers</CardHeader>
        <CardBody>
            <Table>
                <thead>
                    <th>From</th>
                    <th>To</th>
                    <th>Amount</th>
                </thead>
                <tbody>
                    <tr>
                        <td>1234</td>
                        <td>9876</td>
                        <td>500</td>
                    </tr>
                    <tr>
                        <td>2468</td>
                        <td>1357</td>
                        <td>750</td>
                    </tr>
                </tbody>
            </Table>
        </CardBody>
      </Card>
    </Col>
  );
};
export default RecentBank;
