import React, { createContext, useContext, useState } from "react";

const TransactionContext = createContext();

export const TransactionProvider = ({ children }) => {
  const [editModal, setEditModal] = useState(false);
  const toggleEdit = () => setEditModal((prev) => !prev);
  const [importModal, setImportModal] = useState(false);
  const toggleImport = () => setImportModal((prev) => !prev);

  const [editTransactionData, setEditTransactionData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  return <TransactionContext.Provider value={{ editModal, setEditModal, toggleEdit, importModal, setImportModal, toggleImport, setEditTransactionData, editTransactionData, searchTerm, setSearchTerm }}>{children}</TransactionContext.Provider>;
};

export const useTransaction = () => {
  return useContext(TransactionContext);
};
