import React from "react";
import { Button, Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useTransaction } from "../../Context/TransactionContext";
import { useForm } from "react-hook-form";
import axios from "axios";
import { updateTransactionApi } from "../../Api/ApiList";
import toast from "react-hot-toast";

const EditModal = ({ refetchTransaction }) => {
  const { editModal, toggleEdit, editTransactionData } = useTransaction();

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      id: editTransactionData?.id,
      block_number: editTransactionData?.block_number,
      event_id: editTransactionData?.event_id,
      amount: editTransactionData?.amount,
      from_account: editTransactionData?.from_account,
      to_account: editTransactionData?.to_account,
      is_treasury: false,
      starting_balance: 0,
    },
  });

  const handleTransaction = async (data) => {
    const { from_account, from_label, to_account, to_label, starting_balance, is_treasury } = data;
    const conditionalData = {
      from_address: from_label !== "" && to_label !== "" ? from_account : to_label !== "" ? null : from_account,
      from_label,
      to_address: from_label !== "" && to_label !== "" ? to_account : from_label !== "" ? null : to_account,
      to_label,
      starting_balance,
      is_treasury,
    };
    try {
      const token = localStorage.getItem("finance_token");
      const parsedToken = JSON.parse(token);
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/${updateTransactionApi}`, conditionalData, {
        headers: {
          "x-access-token": parsedToken,
        },
      });
      if (response.status === 200) {
        refetchTransaction();
        toast.success(response?.data.message);
        reset();
      }
    } catch (error) {
      toast.error(`${error?.response?.data?.message || error.message}`);
    }
  };

  const onSubmit = (data) => {
    if(data.from_label || data.to_label) {
      handleTransaction(data);
      toggleEdit();
    }
  };

  return (
    <Modal size="md" centered isOpen={editModal} toggle={toggleEdit}>
      <ModalHeader toggle={toggleEdit}>Edit Transaction</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <ModalBody>
          <Row>
            <Col xs="6">
              <FormGroup className="form-group">
                <Label className="mb-2 fw-semibold">No :</Label>
                <input {...register("id")} disabled className="form-control search-transaction" type="text" value={editTransactionData?.id} />
              </FormGroup>
            </Col>
            <Col xs="6">
              <FormGroup className="form-group">
                <label className="mb-2 fw-semibold">Block no :</label>
                <input disabled {...register("block_number")} className="form-control search-transaction" type="text" value={editTransactionData?.block_number} />
              </FormGroup>
            </Col>
            <Col xs="6">
              <FormGroup className="form-group">
                <label className="mb-2 fw-semibold">Event id :</label>
                <input disabled {...register("event_id")} className="form-control search-transaction" type="text" value={editTransactionData?.event_id} />
              </FormGroup>
            </Col>
            <Col xs="6">
              <FormGroup className="form-group">
                <label className="mb-2 fw-semibold">Amount :</label>
                <input disabled {...register("amount")} className="form-control search-transaction" type="text" value={editTransactionData?.amount} />
              </FormGroup>
            </Col>
            <Col xs="12">
              <FormGroup className="form-group">
                <label className="mb-2 fw-semibold">From account :</label>
                <input disabled {...register("from_account")} className="form-control search-transaction" type="text" value={editTransactionData?.from_account} />
              </FormGroup>
            </Col>
            <Col xs="12">
              <FormGroup className="form-group">
                <label className="mb-2 fw-semibold">To account :</label>
                <input disabled {...register("to_account")} className="form-control search-transaction" type="text" value={editTransactionData?.to_account} />
              </FormGroup>
            </Col>
            <Col xs="6">
              <FormGroup className="form-group">
                <label className="mb-2 fw-semibold">From label :</label>
                <input {...register("from_label")} className="form-control search-transaction" type="text" defaultValue={editTransactionData?.from_label} />
              </FormGroup>
            </Col>
            <Col xs="6">
              <FormGroup className="form-group">
                <label className="mb-2 fw-semibold">To label :</label>
                <input {...register("to_label")} className="form-control search-transaction" type="text" defaultValue={editTransactionData?.to_label} />
              </FormGroup>
            </Col>
            <Col xs="6">
              <FormGroup className="form-group">
                <label className="mb-2 fw-semibold">Starting balance :</label>
                <input {...register("starting_balance")} className="form-control search-transaction" type="number" />
              </FormGroup>
            </Col>
            <Col xs="6" className="d-flex align-items-center">
              <div className="check-input">
                <FormGroup className="form-group form-check form-switch mb-0">
                  <input {...register("is_treasury")} type="checkbox" className="switch-box" id="treasury-switch" />
                  <label className="fw-semibold" htmlFor="treasury-switch">
                    Treasury
                  </label>
                </FormGroup>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" type="submit">
            Update
          </Button>
          <Button color="light" className="border border-1" onClick={toggleEdit}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditModal;
