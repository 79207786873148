import ForgotPassword from "../Pages/Auth/ForgotPassword/ForgotPassword";
import LogIn from "../Pages/Auth/Login/LogIn";
import UpdatePassword from "../Pages/Auth/UpdatePassword/UpdatePassword";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Transaction from "../Pages/Transaction/Transaction";

export const protRoutes = [
  { path: "/dashboard", element: <Dashboard /> },
  { path: "/transaction", element: <Transaction /> },
];

export const authRoutes = [
  { path: `${process.env.PUBLIC_URL}/login`, element: <LogIn /> },
  { path: `${process.env.PUBLIC_URL}/forgot_password`, element: <ForgotPassword /> },
  { path: `${process.env.PUBLIC_URL}/update_password/:token`, element: <UpdatePassword /> },
];
