import React from "react";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import { bankBalanceChart } from "../../../Data/Dashboard/DashboardChart";
import ReactApexChart from "react-apexcharts";

const BankBalance = () => {
  return (
    <Col lg="6">
      <Card>
        <CardBody>
          <ReactApexChart
            type="line"
            options={bankBalanceChart}
            series={bankBalanceChart.series}
            height={350}
          />
        </CardBody>
      </Card>
    </Col>
  );
};
export default BankBalance;
