import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import axiosInstance from './middleware';

const useImport = ({ url, refetch, extraFun }) => {
  return useMutation({
    mutationFn: (data) => axiosInstance.post(url, data),
    onSuccess: (resData) => {
      const handleSnackbar = () => {
        if (resData.status === 200) {
          toast.success(resData.data.message);
          if(extraFun){
            extraFun();
          }
        } else {
          toast.error("Error importing file");
        }
      };

      if (refetch) {
        refetch().then(handleSnackbar);
      }
    },
    onError: () => {
      toast.error("Error importing file");
    },
  });
};

export default useImport;
