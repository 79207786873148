import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, Col } from "reactstrap";
import { topBankTransferChart } from "../../../Data/Dashboard/DashboardChart";

const TopBankTransfer = () => {
  return (
    <Col lg="6">
      <Card>
        <CardBody>
          <ReactApexChart
            type="line"
            options={topBankTransferChart}
            series={topBankTransferChart.series}
            height={295}
          />
        </CardBody>
      </Card>
    </Col>
  );
};
export default TopBankTransfer;
