import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { Eye, Key, Mail } from "react-feather";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Label } from "reactstrap";
import { useAuth } from "../../../Context/AuthContext";

const LoginContainer = () => {
  const [show, setShow] = useState(false);
  const [loginSpine, SetLogInSpine] = useState(false);
  const toggleShow = () => setShow(!show);
  const { LoginSchema, handleLogin } = useAuth();
  const {register,handleSubmit,formState: { errors },} = useForm({ resolver: yupResolver(LoginSchema) });
  const navigate = useNavigate();

  const onSubmit = (data) => handleLogin({ data,SetLogInSpine });

  const handleForgotPassword = ()=>{
    navigate(`${process.env.PUBLIC_URL}/forgot_password`,{ state: { forgotToken : true}})
  }

  return (
    <Form className="auth-card" onSubmit={handleSubmit(onSubmit)} noValidate>
      <h2>Login</h2>
      <div className="auth-content">
        <FormGroup className="form-group">
          <Label>Email</Label>
          <div className="position-relative">
            <Mail />
            <input className="form-control" {...register("email")} autoComplete="off" type="email" placeholder="Enter Email ..." />
          </div>
          {errors.email && <span className="text-danger fs-6">{errors.email.message}</span>}
        </FormGroup>
        <FormGroup className="form-group">
          <Label>Password</Label>
          <div className="position-relative">
            <Key />
            <input className="form-control" {...register("password")} autoComplete="off" type={show ? "text" : "password"} placeholder="Enter Password ..." />
          </div>
          <div className={`toggle-password ${show ? "show" : ""}`} onClick={toggleShow}>
            <Eye />
          </div>
          {errors.password && <span className="text-danger fs-6">{errors.password.message}</span>}
        </FormGroup>
        <FormGroup>
          <div className="forgot-pass">
            <div className="form-check p-0">
              <input type="checkbox" className="form-check-input" id="term-check" />
              <label htmlFor="term-check">Remember Me</label>
            </div>
            <Button className="bg-transparent border-0 p-0 text-end w-auto" tag="a" onClick={handleForgotPassword}>{"Forget Password ?"}</Button>
          </div>
        </FormGroup>
        <FormGroup className="mb-0 mt-5 loader-btn">
          <Button disabled={loginSpine ? true : false} type="submit" className="btn-solid">Login {loginSpine && <div className="loader"></div>}</Button>
        </FormGroup>
      </div>
    </Form>
  );
};

export default LoginContainer;
