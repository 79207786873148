import React from "react";
import { AuthProvider } from "./Context/AuthContext";
import Routers from "./Routes";
import { CustomToaster } from "./Utils";
import { TransactionProvider } from "./Context/TransactionContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <TransactionProvider>
          <Routers />
        </TransactionProvider>
        <CustomToaster />
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
